import React from 'react'
import { cn } from '../utils/cn'
import {
  useMotionValue,
  motion,
  useMotionTemplate,
} from 'framer-motion'

export type BackgroundDotsProps = {
  children: React.ReactNode
  className?: string
  containerClassName?: string
}

const BackgroundDots: React.FC<BackgroundDotsProps> = ({
  children,
  className,
  containerClassName,
}) => {
  let mouseX = useMotionValue(0)
  let mouseY = useMotionValue(0)

  const handleMouseMove = ({
    currentTarget,
    clientX,
    clientY,
  }: React.MouseEvent<HTMLDivElement>) => {
    if (!currentTarget) return
    let { left, top } = currentTarget.getBoundingClientRect()
    mouseX.set(clientX - left)
    mouseY.set(clientY - top)
  }

  return (
    <div
      className={cn(
        'relative h-[40rem] flex items-center bg-white dark:bg-black justify-center w-full group',
        containerClassName,
      )}
      onMouseMove={handleMouseMove}
    >
      <div className='absolute inset-0 bg-dot-thick-neutral-300 dark:bg-dot-thick-neutral-800 pointer-events-none' />
      <motion.div
        className='pointer-events-none bg-dot-thick-indigo-500 dark:bg-dot-thick-indigo-500 absolute inset-0 opacity-0 transition duration-300 group-hover:opacity-100'
        style={{
          WebkitMaskImage: useMotionTemplate`
            radial-gradient(
              200px circle at ${mouseX}px ${mouseY}px,
              black 0%,
              transparent 100%
            )
          `,
          maskImage: useMotionTemplate`
            radial-gradient(
              200px circle at ${mouseX}px ${mouseY}px,
              black 0%,
              transparent 100%
            )
          `,
        }}
      />

      <div className={cn('relative z-20 w-full', className)}>
        {children}
      </div>
    </div>
  )
}

export default BackgroundDots
